import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/CallToAction.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/CallToBoost.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/CounterSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/FormContactUs.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/HeroSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/ImageBox.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/IntroSection.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/LatestBlog.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/Locations.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/LocationSection.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/PortfolioWebDesign.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/ServicesHome.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/ServicesList.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/TechnologySection.jsx");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/prateeksha.com/next.js/src/components/WhyChooseUsSection.jsx");
