'use client';
import { useState } from 'react';
import Link from 'next/link';
import {
  FaMobileAlt,
  FaShoppingCart,
  FaCode,
  FaAppStore,
} from 'react-icons/fa';

const primaryColors = 'bg-colorOrange-500 text-neutral-800';
const secondaryColors = 'bg-neutral-800 text-white';

const ServicesHome = ({
  title = 'What Variety of Web Solutions Does',
  colouredTitle = 'Our Web Designing Company Offer?',
  items = [
    {
      tabTitle: 'Web Design',
      title: 'Web Design Services',
      description:
        "Do you want a beautiful, user-friendly website? We use WordPress's variety, Gatsby.js' speed, or Next.js' cutting-edge capabilities to turn your ideas into a website that looks great, functions great, and stands out online.",
      icon: <FaMobileAlt />,
      link: '/webdesign',
    },
    {
      tabTitle: 'Ecommerce',
      title: 'Ecommerce Website Design',
      description:
        'Ecommerce Website Design helps you maximise online business profits. Our web development agency develops reliable, user-friendly Ecommerce solutions for all types of enterprises. Your Shopify, Shopware, or WooCommerce store may look better and sell more with us.',
      icon: <FaShoppingCart />,
      link: '/ecommerce',
    },
    {
      tabTitle: 'Web Development',
      title: 'Web Development',
      description:
        'Our Web Development services boost your online visibility. We combine Laravel with React to create powerful, easy-to-use web apps. Our developers can create complicated online portals and interactive web apps with a great user experience and reliable performance.',
      icon: <FaCode />,
      link: '/web-development',
    },
    {
      tabTitle: 'Mobile App',
      title: 'Mobile App Development',
      description:
        'Our mobile app development services bring your ideas to life with functional and user-friendly applications for iOS and Android, enhancing user engagement and business performance.',
      icon: <FaAppStore />,
      link: '/mobile-app-development',
    },
  ],
}) => {
  return (
    <section className="py-4 services-home">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
        <h2 className="text-3xl lg:text-5xl font-extrabold text-neutral-800">
          {title} <span className="text-colorOrange-600">{colouredTitle} </span>
        </h2>
        {/* Grid Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          {items.map((service, index) => (
            <div
              key={index}
              className={`p-6 rounded-lg shadow-md flex flex-col items-start ${
                index % 2 === 0 ? primaryColors : secondaryColors
              }`}
            >
              <div className="flex items-center justify-center my-4 mb-6 text-6xl">
                {service.icon}
              </div>
              <h3 className="text-3xl font-bold mb-2">{service.title}</h3>
              <p className="text-lg">{service.description}</p>
              {service.link && (
                <Link
                  href={service.link}
                  className={`text-base font-semibold py-1 px-4 mt-4  ${
                    index % 2 === 0
                      ? 'bg-neutral-700 text-colorOrange-500 hover:bg-colorOrange-800 hover:text-neutral-500'
                      : 'bg-colorOrange-500 text-neutral-800 hover:bg-neutral-700 hover:text-colorOrange-600'
                  }`}
                >
                  Learn More
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesHome;
